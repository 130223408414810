var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { class: _vm.class_name }, [
    _vm.time_spent
      ? _c(
          "div",
          {
            staticClass: "time",
            class: {
              over: _vm.time_spent > _vm._time_allocated,
              below:
                _vm.time_spent < _vm._time_allocated &&
                _vm.component.status !== _vm.COMPONENT_STATUS.ACTIVE,
              reportable: _vm.has_reported_hours_rounding,
            },
          },
          [
            _vm.has_reported_hours_rounding
              ? _c("strong", [
                  _vm._v(
                    " " +
                      _vm._s(_vm._f("milli2duration")(_vm._time_reportable)) +
                      " "
                  ),
                ])
              : _c("strong", [
                  _vm._v(
                    " " + _vm._s(_vm._f("milli2duration")(_vm.time_spent)) + " "
                  ),
                ]),
            _vm._v(" spent "),
            _vm._has_allocated
              ? [
                  _vm._v(" of "),
                  _c("b", [
                    _vm._v(
                      " " +
                        _vm._s(_vm._f("milli2duration")(_vm._time_allocated)) +
                        " "
                    ),
                  ]),
                ]
              : _vm._e(),
          ],
          2
        )
      : _vm._time_allocated
      ? _c("div", { staticClass: "time" }, [
          _c("strong", [
            _vm._v(_vm._s(_vm._f("milli2duration")(_vm._time_allocated))),
          ]),
          _vm._v(" estimated "),
        ])
      : _vm._e(),
    _vm.show_invoiced && _vm.invoiced_hours
      ? _c(
          "span",
          {
            directives: [
              { name: "only-super-admin", rawName: "v-only-super-admin" },
            ],
            staticClass: "hidden",
          },
          [
            _vm._v(" Invoiced "),
            _c("strong", { staticStyle: { "margin-left": "4px" } }, [
              _vm._v(
                " " + _vm._s(_vm._f("hours2duration")(_vm.invoiced_hours)) + " "
              ),
            ]),
          ]
        )
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }