import { render, staticRenderFns } from "./TimeSpent.vue?vue&type=template&id=276a1fb4&scoped=true"
import script from "./TimeSpent.vue?vue&type=script&lang=js"
export * from "./TimeSpent.vue?vue&type=script&lang=js"
import style0 from "./TimeSpent.vue?vue&type=style&index=0&id=276a1fb4&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "276a1fb4",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/opt/atlassian/pipelines/agent/build/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('276a1fb4')) {
      api.createRecord('276a1fb4', component.options)
    } else {
      api.reload('276a1fb4', component.options)
    }
    module.hot.accept("./TimeSpent.vue?vue&type=template&id=276a1fb4&scoped=true", function () {
      api.rerender('276a1fb4', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/pages/sessions/components/TimeSpent.vue"
export default component.exports